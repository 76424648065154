<template>
  <component :is="eventData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
        variant="danger"
        :show="eventData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching event data
      </h4>
      <div class="alert-body">
        No event found with this event id. Check
        <b-link
            class="alert-link"
            :to="{ name: 'events'}"
        >
          Events List
        </b-link>
        for other events.
      </div>
    </b-alert>
    <b-form @submit.prevent="onUpdate">
      <b-row>
        <b-col md="6">
          <b-form-group
              label="Title En"
              label-for="mc-title-en"
          >
            <b-form-input
                v-model="eventData.title_en"
                id="mc-title-en"
                placeholder="Title En"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Title Ar"
              label-for="mc-title-ar"
          >
            <b-form-input
                v-model="eventData.title_ar"
                id="mc-title-ar"
                placeholder="Title Ar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Event Url"
              label-for="mc-event-url"
          >
            <b-form-input
                v-model="eventData.event_url"
                id="mc-event-url"
                placeholder="Event Url"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Is Active"
              label-for="is_active"
          >
            <b-form-checkbox
                id="is_active"
                v-model="eventData.is_active"
                :checked=eventData.is_active
                autofocus
                class="custom-control-success"
                name="check-button"
                switch
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- Flag -->
          <file
              title="Select Event Image"
              :default_place_holder_src="eventData.image"
              :edit="true"
              @file_uploaded="({media})=>{eventData.image= media.url; imageId = media.id}"

          ></file>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            Submit
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </component>
</template>

<script>

import {ref, onUnmounted} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import eventStoreModule from '../eventStoreModule'
import {BAlert, BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BLink, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BAlert,
    BLink,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton
  },
  directives: {
    Ripple,
  },
  setup() {
    const blankEventData = {
      title_en: '',
      title_ar: '',
      is_active: true,
      event_url: '',
      image: null,
      image_id: null
    }
    const imageId = ref(null);
    const toast = useToast()

    const eventData = ref(JSON.parse(JSON.stringify(blankEventData)))

    const EVENT_APP_STORE_MODULE_NAME = 'app-event'

    // Register module
    if (!store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.registerModule(EVENT_APP_STORE_MODULE_NAME, eventStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVENT_APP_STORE_MODULE_NAME)) store.unregisterModule(EVENT_APP_STORE_MODULE_NAME)
    })
    store.dispatch('app-event/fetchEvent', {id: router.currentRoute.params.id})
        .then(response => {
          eventData.value = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            eventData.value = undefined
          }
        })

    const onUpdate = () => {
      let data = {
        title_en: eventData.value.title_en,
        title_ar: eventData.value.title_ar,
        is_active: eventData.value.is_active,
        event_url: eventData.value.event_url,
        image: eventData.value.image
      }
      if (imageId.value != null) {
        data.image = imageId.value
      }else{
        data.image = null
      }
      store.dispatch('app-event/updateEvent', {
        id: router.currentRoute.params.id,
        data: data
      })
          .then(response => {
            router.push({name : 'events'})
            toast({
              component: ToastificationContent,
              props: {
                title: 'Event Updated Successfully',
                icon: 'EditIcon',
                position: 'top-center',
                variant: 'success',
              },
            }, {
              position: 'top-center'
            })
          })
          .catch(error => {

          })
    }
    return {
      onUpdate,
      eventData,
      imageId
    }
  },
}
</script>

<style>

</style>
